.product {
    background-color: #f0f0f0;
}

.product .filter {
    background-color: #e6e3e3;
}

.allSub .product .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    grid-gap: 20px;
    align-items: stretch;
    justify-items: center;
    /* background-color: rgb(245, 245, 245); */
    /* padding: 24px; */
    /* height: 225px; */
}


.allSub .product .grid img {
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    max-width: 100%;
}

.product .paggination {
    background-color: #f5f5f5;
}

.product .paggination .page-item a {
    border: 0;
    background-color: transparent;
    color: black;
    font-weight: 600;
    padding: 8px 12px;
    box-shadow: none;
}

.product .paggination .page-item.active .page-link {
    border: 0;
    border-radius: 2px;
    background-color: rgb(74, 74, 206);
    color: white;
    font-weight: 600;
    padding: 8px 12px;
}


.icon {
    background-color: whitesmoke;
    height: 60px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter_icon {
    background-color: whitesmoke;
    height: 40px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter_icon svg{
    height: 30px;
    width: 30px;
    color: #918D8D;
    display: inline-block;
}
.icon svg{
    height: 40px;
    width: 40px;
    color: #918D8D;
    display: inline-block;
}


.filter .form-select, .filter .form-select:focus{
    box-shadow: none;
    border: 0;
}

.content_filter_wrapper  {
    visibility: visible;
    z-index: 20;
    opacity: 1;
    position: absolute;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    right: 0%;
    left: 0%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    padding: 0 24px;
    background-color: white;
    
}
.content_filter_wrapper  .content {
    border-right: 2px solid #f5f5f5;
    border-left: 2px solid #f5f5f5;
}
.content_filter_wrapper  .content  .accordion-item {
    border: 0;
}
.content_filter_wrapper  .content  .accordion-body {
    background-color: #f9f9f9;
    height: 100%;
}
.content_filter_wrapper  .content  .accordion-button,.content_filter_wrapper .content .accordion-button:not(.collapsed){
    border: 0;
    box-shadow: none;
    background-color: transparent;
    color: black;
    font-weight: 600;
}

.content_filter_wrapper  .content  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23YOUR_HEX_CODE'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

@media screen and (max-width: 575px) {
    .allSub .product .grid {
        padding: 0;
        grid-template-columns: 1fr 1fr;
    }
    .content_filter_wrapper {
        grid-template-columns: 1fr;
    }

    .allSub .grid .image img{
        height: 142px;
    }

    .allSub .popular_template .slick-slider .slick-prev {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        left: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
    .allSub .popular_template .slick-slider .slick-next {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        right: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }


}

@media (min-width: 576px) and (max-width: 767px) {
    .allSub .product .grid {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0;
    }
    .content_filter_wrapper {
        grid-template-columns: 1fr 1fr ;
    }
    .allSub .grid .image img{
        width: 230px;
        height: 142px;
    }
    .allSub .popular_template .slick-slider .slick-prev {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        left: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
    .allSub .popular_template .slick-slider .slick-next {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        right: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .allSub .product .grid {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0;
    }
    .content_filter_wrapper {
        grid-template-columns: 1fr 1fr 1fr ;
    }
    .allSub .popular_template .slick-slider .slick-prev {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        left: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
    .allSub .popular_template .slick-slider .slick-next {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        right: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .allSub .product .grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .allSub .popular_template .slick-slider .slick-prev {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        left: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
    .allSub .popular_template .slick-slider .slick-next {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        right: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
}
@media (min-width: 1200px)  {
    
    .allSub .popular_template .slick-slider .slick-prev {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        left: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
    .allSub .popular_template .slick-slider .slick-next {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        right: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
}

/* @media (min-width: 1200px) and (max-width: 1400px)  {
    .content_filter_wrapper  {
        visibility: visible;
        z-index: 20;
        opacity: 1;
        position: absolute;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
        right: 16%;
        left: 16%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
        padding: 0 24px;
        background-color: white;
        
    }
    
    .allSub .popular_template .slick-slider .slick-prev {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        left: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
    .allSub .popular_template .slick-slider .slick-next {
        color: black;
        width: 30px;
        height: 40px;
        z-index: 9;
        right: -15px;
        top: 45%;
        border-radius: 5px;
        padding: 10px;
    }
} */
