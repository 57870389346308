.footer.footer_bg {
    /* background: black; */
    background: #1E1E1E;
}
.footer .single_footer .footer_des{
    line-height: 1.8;
}
.footer .single_footer .card_logo {
    filter: grayscale(100%);
}
.footer .single_footer .social_icon li{
    transition: 0.3s linear;
    cursor: pointer;
}
.footer .single_footer .social_icon li:hover{
    transform: scale(-1, 1);
}
.footer .single_footer ul li a:hover {
    text-decoration: underline;
    color: var(--white);
    padding-left: 2px;
}

.footer .single_footer .form-select{
    color: white;
    background-color: transparent;
    border: 1px solid #696969;
    box-shadow: none;
}

.footer .footer-border {
    border-top: 1px solid rgba(255,255,255,0.2);
}


/* font size */

.font_size_16 {
    font-size: 1rem;
}