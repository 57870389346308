.product_thumb .slick-thumb {
    bottom: -46px;
    display: flex !important;
    justify-content: center;
    left: 50% ;
    transform: translateX(-50%) ;
    overflow: hidden;

}

/* css by maruf */

.also_available .team_img img {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
.similar_design .team_img img {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.cl_dark_hover,
.cl_dark_hover:hover {
  color: var(--dark);
}
.login_modal {
    width: 100% !important;
    margin: 0 auto !important;
    
}

/* .login_modal .login_btn{
    width: 300px !important;

} */
/* css by maruf */

.product_thumb .slick-thumb li {
    width: 100px;
    height: 46px;
}

.product_thumb .slick-thumb li.slick-active img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.product_thumb .slick-thumb li img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.product_thumb .main_image .team_img img {
    height: 450px;
    object-fit: cover;
}

.middle_banner {
    background-color: black;
}

.middle_banner .rightbar {
    padding: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.middle_banner .rightbar .content .legal_info {
    bottom: 20px;
}

.middle_banner .rightbar .btnn {

    display: inline-block;
    position: relative;
    letter-spacing: 0;
    z-index: 10;
}

.middle_banner .rightbar .btnn::before {

    /* border-color: #e62429; */
    /* -webkit-transition: none; */
    /* transition: none; */
    /* border-style: solid; */
    /* border-width: 0 0 16px 16px; */
    /* -webkit-box-sizing: border-box; */
    /* box-sizing: border-box; */

    background-color: #e62429;
    margin-left: 16px;
    content: "";
    display: block;
    height: 12px;
}

.middle_banner .rightbar .btnn::after {
    background-color: #e62429;
    margin-right: 16px;
    content: "";
    display: block;
    height: 12px;
}

.innerfill {
    color: #fff;
    background-color: #e62429;
    -webkit-transition: none;
    transition: none;
    text-align: center;

    display: block;
    padding: 0 35px;

    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;

}

.innerfill::before {
    border-color: #e62429 transparent;
    border-style: solid;
    border-width: 0 0 12px 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}

.innerfill::after {
    border-color: #e62429 transparent;
    border-style: solid;
    border-width: 0 0 12px 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.overview_title:before {
    left: 72px;
    top: -2px;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}

.overview_title:after {
    left: 10px;
    top: calc(100% + 3px);
    -webkit-transform-origin: right top;
    transform-origin: right top;
}

.overview_title:before,
.overview_title:after {


    background-color: #c6a972;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    -webkit-transform: rotate(-45deg) skewX(45deg);
    transform: rotate(-45deg) skewX(45deg);
    width: 25px;
}

.product_buy {
    margin-top: 10rem;
}

.product_buy .btn {
    background-color: var(--light-blue);
}

.product_buy .icon {
    border: 1px solid var(--light-blue);
    /* padding: 0.6rem 1rem; */
    background: transparent;
    height: 47px;
    width: 57px;
}
.product_buy .icon svg {
    height: 23px;
    width: 23px;
    /* font-size: 500px; */
    /* border: 1px solid var(--light-blue); */
    /* padding: 0.6rem 1rem; */
    /* background: transparent; */
    display: inline-block;
    color: var(--light-blue);
}
.product_specs .icon svg {
    /* border: 1px solid var(--light-blue); */
    /* padding: 0.6rem 1rem; */
    /* font-size: 25px; */
    height: 20px;
    width: 20px;
    color: #918D8D;
}
.product_specs .icon{
    height: 20px;
    width: 20px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
}

.productDetails .gallery1 .slick-slider .slick-prev {
    color: black;
    z-index: 9;
    left: -15px;
   top: 40%;
    border-radius: 5px;
    padding: 10px;
}

.productDetails .gallery1 .slick-slider .slick-next{
    color: black;
    z-index: 9;
    right: -15px;
   top: 40%;
    border-radius: 5px;
    padding: 10px;
}
.productDetails .gallery2 .slick-slider .slick-prev {
    color: black;
    z-index: 9;
    left: -15px;
    top: 25%;
    border-radius: 5px;
    padding: 10px;
}
.productDetails .gallery2 .slick-slider .slick-next {
    color: black;
    z-index: 9;
    right: -15px;
    top: 25%;
    border-radius: 5px;
    padding: 10px;
}

@media screen and (max-width: 575px) {
    .productDetails .content .slick-slider .slick-prev,
    .productDetails .content .slick-slider .slick-next{
        width: 30px;
        height: 40px;
    }
 
}
@media (min-width: 576px) and (max-width: 767px) {
   
    .productDetails .content .slick-slider .slick-prev {
        width: 30px;
        height: 40px; 
    }
    .productDetails .content .slick-slider .slick-next {
        width: 30px;
        height: 40px;
    }
}
@media screen and (max-width: 767px) {
    .middle_banner .rightbar {
        padding: 20px 50px 50px 50px;
    }

    .product_thumb .main_image .team_img img {
        height: 200px;
    }

    .product_buy {
        margin-top: 2rem;
    }

    .productDetails .btn-primary {
        padding: 0.3rem 1rem;
        font-size: 16px;
    }
    .product_buy .icon {
        padding: 0.32rem 1rem;
        height: 38px;
        width: 50px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .middle_banner .rightbar {
        padding: 20px 50px 50px 50px;
    }

    .product_thumb .main_image .team_img img {
        height: 250px;
    }

    .product_buy {
        margin-top: 8.7rem;
    }

    .productDetails .btn-primary {
        padding: 0.3rem 1rem;
        font-size: 16px;
    }
    .product_buy .icon {
        padding: 0.32rem 1rem;
    }

    .productDetails .content .slick-slider .slick-prev {
        width: 30px;
        height: 40px;
    }
    .productDetails .content .slick-slider .slick-next {
        width: 30px;
        height: 40px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    
    .productDetails .content .slick-slider .slick-prev {
        width: 30px;
        height: 40px;
    }
    .productDetails .content .slick-slider .slick-next {
        width: 30px;
        height: 40px;
    }
}

@media (min-width: 1200px) {
    .productDetails .content .slick-slider .slick-prev {
        width: 30px;
        height: 40px;
    }
    .productDetails .content .slick-slider .slick-next {
        width: 30px;
        height: 40px;
    }
}