@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Oswald:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Barlow', sans-serif;

}


:root {
  --dark: #000;
  --dark-red:#e62429;
  --light-dark: #1E1E1E;
  --white: #ffff;
  --light-grey: #C1C1C1;
  --grey: #565656;;
  --medium-grey2: #0000008c;
  --medium-grey: #918d8d;
  --light-green: #287a5c;
  --light-green2: #268b66;
  --light-blue: #0068c1;

}

.font_oswald {
  font-family: 'Oswald', sans-serif;
}

/* color */

.cl_white {
  color: var(--white);
}
.cl_dark_red {
  color: var(--dark-red);
}

.cl_dark {
  color: var(--dark);
}

.cl_white_hover,
.cl_white_hover:hover {
  color: var(--white);
}

.cl_dark_hover,
.cl_dark_hover:hover {
  color: var(--dark);
}

.cl_grey {
  color: var(--grey);
}
.cl_light_grey {
  color: var(--light-grey);
}

.cl_medium_grey {
  color: var(--medium-grey);
}
.cl_medium_grey_header2 {
  color: #e8e3e3;
}
.cl_medium_grey_header1 {
  color: #e8e3e3;
}

.cl_medium_grey2 {
  color: var(--medium-grey2);
}

.light_blue {
  color: var(--light-blue);
}

.p_cl {
  color: var(--medium-grey);
}

.link_cl {
  color: var(--light-green);
}

.link_cl:hover {
  color: var(--light-green2);
}

/* background color */
.bg_light_dark {
  background-color: var(--light-dark);
}

.bg_dark {
  background-color: var(--dark);
}

.bg_ligh_blue {
  background-color: var(--light-blue);
}

.bg_ligh_grey {
  background-color: var(--light-grey);
}

/* font weight */

.fw_400 {
  font-weight: 400;
}

.fw_500 {
  font-weight: 500;
}

.fw_600 {
  font-weight: 600;
}

.fw_700 {
  font-weight: 700;
}


/* font size */

.font_size_50 {
  font-size: 50px;
}

.font_size_35 {
  font-size: 35px;
}

.font_size_30 {
  font-size: 30px;
}

.font_size_24 {
  font-size: 24px;
}

.font_size_20 {
  font-size: 20px;
}

.font_size_18 {
  font-size: 18px;
}

.font_size_16 {
  font-size: 16px;
}
.font_size_12 {
  font-size: 12px;
}
.font_size_14 {
  font-size: 14px;
}
.font_size_15 {
  font-size: 15px;
}


/* margin pading */

.sesction_pt {
  padding-top: 4rem;
}

.sesction_pb {
  padding-bottom: 4rem;
}

.sesction_mt {
  margin-top: 4rem;
}

.sesction_mb {
  margin-bottom: 4rem;
}


/* utility css */
.underline_none {
  text-decoration: none;
}
/* customize by maruf */
.underline_none:hover{
  color:rgb(255, 255, 255)
}
/* customize by maruf */

.ls_none {
  list-style: none;
}

.pointer {
  cursor: pointer;
}

.transition_3 {
  transition: .3s linear;
}

.ls_space3 {
  letter-spacing: 3px;
}

.w80 {
  width: 80%;
}
.w20 {
  width: 20%;
}


@media screen and (max-width: 767px) {

  .sesction_pt {
    padding-top: 2rem;
  }
  
  .sesction_pb {
    padding-bottom: 2rem;
  }
  
  .sesction_mt {
    margin-top: 2rem;
  }
  
  .sesction_mb {
    margin-bottom: 2rem;
  }
  

  .custom_container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

  .custom_container.container_fluid {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

  .font_size_50 {
    font-size: 30px;
  }

  .font_size_35 {
    font-size: 25px;
  }

  .font_size_30 {
    font-size: 20px;
  }

  .font_size_24 {
    font-size: 18px;
  }

  .font_size_20 {
    font-size: 18px;
  }

  .font_size_18 {
    font-size: 16px;
  }

  .font_size_16 {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .custom_container {
    max-width: 730px;
    margin: 0 auto;
    /* padding: 0 15px; */
  }

  .custom_container.container_fluid {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

  .custom_container.container_fluid_md {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

  .font_size_50 {
    font-size: 40px;
  }

  .font_size_35 {
    font-size: 30px;
  }

  .font_size_30 {
    font-size: 25px;
  }

  .font_size_24 {
    font-size: 20px;
  }

  .font_size_20 {
    font-size: 18px;
  }

  .font_size_18 {
    font-size: 16px;
  }

  .font_size_16 {
    font-size: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .custom_container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .custom_container.container_fluid {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .custom_container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .custom_container.container_fluid {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1440px) and (max-width: 1920px) {
  .custom_container {
    max-width: 1330px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .custom_container.container_fluid {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

}

@media screen and (min-width: 1921px) {
  .custom_container {
    max-width: 1600px !important;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide>div {
  padding: 0 5px;
}

.slick-next,
.slick-prev {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


