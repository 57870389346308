.header img {
    height: 40px;
}
#offcanvasNavbar-expand-lg {
    width: 100% !important;
}


.header .form-control,.header .form-control:hover,.header .form-control:focus {
    padding: 1rem 0.75rem;
    box-shadow: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 3px solid rgb(224, 218, 218);
    background-color: transparent;
    color: whitesmoke;
    font-weight: 600;
}
.header .form-control::placeholder{
    font-size: 18px;
    font-weight: 600;
}
.header .btn,.header .btn:hover,.header .btn:active,.header .btn:first-child:active,.header .btn:first-child:focus,.header .btn:focus-visible,.header .btn.show {
    background-color: transparent;
    color: grey;
    font-size: 18px;
    font-weight: 600;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 3px solid rgb(224, 218, 218);
    box-shadow: none;
}
@media screen and (max-width: 991px) {
    .header_desktop_list{
        display: none !important;
    }
    
}
@media screen and (min-width: 992px) {
    .header_desktop_list{
       display: flex;
       flex-wrap: wrap;
    }
    
}