.header2 img {
    height: 40px;
}
#offcanvasNavbar-expand-lg {
    width: 100% !important;
}

.header2 .search_dropdown{
    border-bottom: 3px solid #282828;
}
.header2 .head .nav_items{
   color: "#e8e3e3"
} 
.header2 .search_dropdown .form-select{
    background-image: url('../../../Assets/Common/down-arrow.png');
    background-size: 15px 15px;
    padding-left: 0;
    /* color: #2c2d2e; */
}
.header2_down_part {
    background-color: white;
}
.header2 .input-group-text.border_none {
    border: none;
    padding: 0;
    background-color: transparent;

}


.header2 .input-group .form-control ,.header2 .input-group>.form-control:hover,.header2 .input-group>.form-control:focus {
    padding: 0.5rem 0.75rem;
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-color: transparent;
    /* color: whitesmoke; */
    font-weight: 500;
    min-width: 400px;
}
.header2 .form-control::placeholder{
    font-size: 18px;
    color: #8a92a6;
    font-weight: 200;
}
.header2 .btn,.header2 .btn:hover,.header2 .btn:active,.header2 .btn:first-child:active,.header2 .btn:first-child:focus,.header2 .btn:focus-visible,.header2 .btn.show {
    background-color: transparent;
    color: grey;
    font-size: 18px;
    font-weight: 600;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    border-bottom: 3px solid rgb(224, 218, 218);
    box-shadow: none;
}
@media screen and (max-width: 991px) {
    .header2_desktop_list{
        display: none !important;
    }
    
}
@media screen and (min-width: 992px) {
    .header2_desktop_list{
       display: flex;
       flex-wrap: wrap;
    }
    
}