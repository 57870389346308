/* html{
    background: #243b55;
    color: white;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
} */

.TagInput .Tags{
    background: transparent;
    border: 1px solid rgb(226, 224, 224);
    border-radius: 5px;
    display: flex;
    padding: 6px;
}
.TagInput .Tag{
    align-items: center;
    background: #e9ecef;
    border-radius: 5px;
    display: flex;
    margin: 3px;
    padding: 1px 2px;
}
.TagInput input{
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
}
.TagInput button{
    align-items: center;
    background: rgba(236, 6, 6, 0.614);
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    height: 15px;
    justify-content: center;
    margin: 2px;
    outline: none;
    width: 15px;
    font-size: 10px;
}
.TagInput .Hint{
    font-size: small;
}