.all_Blog {
    background-color: #f0f0f0;
}

.all_Blog .grid img {
    /* box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3); */
    max-width: 100%;
}

.all_Blog .paggination {
    background-color: #f5f5f5;
}

.all_Blog .paggination .page-item a {
    border: 0;
    background-color: transparent;
    color: black;
    font-weight: 600;
    padding: 8px 12px;
    box-shadow: none;
}

.all_Blog .paggination .page-item.active .page-link {
    border: 0;
    border-radius: 2px;
    background-color: rgb(74, 74, 206);
    color: white;
    font-weight: 600;
    padding: 8px 12px;
}


.all_Blog .pagination_box .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    border: 0;
    border-radius: 2px;
    background-color: rgb(74, 74, 206);
    color: rgb(255, 255, 255);
    font-weight: 600;
    padding: 8px 12px;
}

/* css by maruf */

/* .all_Blog .cl_dark_hover,
.all_Blog .cl_dark_hover:hover {
  color: var(--dark);
} */
/* css by maruf */

/* utility css */
/* .underline_none {
    text-decoration: none;
  } */
  /* customize by maruf */
  /* .underline_none:hover{
    color:rgb(255, 255, 255)
  } */
  /* customize by maruf */
.icon {
    background-color: whitesmoke;
    height: 60px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon svg{
    height: 40px;
    width: 40px;
    color: #918D8D;
    display: inline-block;
}
.image h3 {
 color: #000;
 font-size: 20px;  
 padding-top: 10px;
}
.image a {
    text-decoration: none;  
}
.image a:hover h3{
    color: none;  
}

@media screen and (max-width: 575px) {
    .all_Blog .grid {
        display: grid;
        grid-template-columns: 1fr;
    }
    .filter_wrapper {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .all_Blog .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .filter_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr ;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .all_Blog .grid {
        display: grid;
        grid-template-columns: 1fr 1fr ;
        gap: 20px;
    }
    .filter_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .all_Blog .grid {
        display: grid;
        grid-template-columns: 1fr 1fr ;
        gap: 20px;
    }
    .filter_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
    }
}

@media (min-width: 1200px) {
    .all_Blog .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
    .filter_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
    }
}