.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 100px;
  right: 50px;
  z-index: 2;
}

/* .icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  background-color: #fa0909;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
} */

.icon-style {
  background-color: #ff0000;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  color: #fff;
  cursor: pointer;
  /* animation: movebtn 3s ease-in-out infinite; */
  animation: movebtn 3s cubic-bezier(.61,.61,1,1) infinite;
  
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #ff0000;
}

@keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }